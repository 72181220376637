<template>
  <b-card-code>
    <b-form @submit.prevent="save(item)">
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Nº Centro"
            label-for="codigo"
          >
            <b-form-input
              id="codigo"
              v-model="item.codigo"
              placeholder="Código"
              required
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Centro de Costo"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="item.centro"
              placeholder="Nombre"
              required
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="% Dist. Gastos"
            label-for="outgo"
          >
            <cleave
              id="outgo"
              v-model="item.gastos"
              class="form-control"
              :raw="true"
              :options="options.prefix"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="% Dist. Ingresos"
            label-for="income"
          >
            <cleave
              id="income"
              v-model="item.ingresos"
              class="form-control"
              :raw="true"
              :options="options.prefix"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <b-form-group
            label="Criterio de Distribución"
            label-for="criterion"
          >
            <v-select
              id="criterion"
              v-model="item.idCriterio"
              label="criterio"
              :options="criteria"
              :reduce="option => option.idCriterio"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
            label="Tipo de Centro"
            label-for="type"
          >
            <v-select
              id="type"
              v-model="item.idTipo"
              label="tipo"
              :options="types"
              :reduce="option => option.idTipo"
              required
            >
              <template #search="{attributes, events}">
                <input
                  class="vs__search"
                  :required="!item.idTipo"
                  v-bind="attributes"
                  v-on="events"
                >
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
            label="Gerencia Directiva"
            label-for="management"
          >
            <v-select
              id="management"
              v-model="item.idGerencia"
              label="gerencia"
              :options="managements"
              :reduce="option => option.idGerencia"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <b-form-group
            label="Receptora"
            label-for="generate"
          >
            <v-select
              id="generate"
              v-model="item.genera"
              label="label"
              :options="generateOptions"
              :reduce="option => option.value"
            />

          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
            label="Estado"
            label-for="status"
          >
            <v-select
              id="status"
              v-model="item.status"
              label="status"
              :options="statuses"
              :reduce="option => option.idStatus"
            />

          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <!-- submit and reset -->
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            :disabled="loading"
          >
            <span v-if="!loading">Guardar</span>
            <span v-if="loading">Un momento...</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            to="../cost-centers"
          >
            Cancelar
          </b-button>
        </b-col>

        <p>{{ errorMessage }}</p>
      </b-row>
    </b-form>
  </b-card-code>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import BCardCode from '@core/components/b-card-code'
import Cleave from 'vue-cleave-component'
import environment from '@/environment'

export default {
  name: 'CentersTypesAddVue',
  components: {
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    vSelect,
    Cleave,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      item: {},
      loading: true,
      errorMessage: '',
      generateOptions: [
        {label: 'Ambas', value: 'A'},
        {label: 'Ingresos', value: 'I'},
        {label: 'Gastos', value: 'G'},
        {label: 'Ninguna', value: 'N'}
      ],
      options: {
        prefix: {
          prefix: ' %',
          numeralIntegerScale: 2,
          numeralDecimalScale: 2,
          numeral: true,
          tailPrefix: true,
          numeralPositiveOnly: true,
          rawValueTrimPrefix: true,
        },
      },
    }
  },
  computed: {
    criteria() {
      return this.$store.getters['catalog/allCriteria'].filter(item => item.idCriterio !== 2 )
    },
    statuses() {
      return this.$store.getters['catalog/allStatuses']
    },
    types() {
      return this.$store.getters['catalog/allTypes']
    },
    managements() {
      return this.$store.getters['catalog/allManagements']
    },
  },
  created() {
    this.load()
  },
  methods: {
    async load() {
      try {
        this.loading = true
        const {data} = await this.$http.get(`${environment.uri}/ccenters/id/${this.$route.params.id}`)
        this.item = data
        this.item.gastos *= 100
        this.item.ingresos *= 100
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    save(item) {
      this.loading = true
      this.errorMessage = ''

      this.$http.put(`${environment.uri}/ccenters/update/id/${this.$route.params.id}`, {
        ...item,
        gastos: (item.gastos / 100) || 0,
        ingresos: (item.ingresos / 100) || 0,
      })
          .then(() => {
            this.loading = false
            this.$router.replace('/cda/cost-centers')
          })
          .catch(error => {
            console.error(error)
            this.errorMessage = (error && error.apiMessage) ? error.apiMessage : 'No fue posible procesar su solicitud'
            this.loading = false
          })
    },
  },
}
</script>

<style scoped>

</style>
